import { Injectable } from '@angular/core';
import {
    FilterQueryOp,
    EntityQuery,
    Predicate
} from 'breeze-client';
import { SettingName } from './setting-name.enum';

import { BaseEntityService } from '../services/base-entity.service';
import { DataManagerService } from '../services/data-manager.service';
import type { FacetType, IFacetSetting } from './facet-settings/facet-settings.interface';
import { TranslationService } from '../services/translation.service';
import { getSafeProp, notEmpty } from '../common/util';
import { TaskValidationService } from '../tasks/task-validation.service';
import { FeatureFlagService } from '../services/feature-flags.service';
import { Permit, TaxonCharacteristic } from '../common/types';
import { PermitSpecies } from '../common/types/models/permit-species.interface';

@Injectable()
export class SettingService extends BaseEntityService {
    readonly ENTITY_TYPE = 'Settings';
    readonly ENTITY_NAME = 'Setting';

    isGLP: boolean;

    private readonly specialValidationFields: Record<string, string[]> = {
        'job': [
            'JobLine[0]',
            'JobInstitution[0]',
            'JobLocation[0]',
            'JobGroup.JobGroupTreatment[0]',
            'JobTestArticle[0]'
        ],
        'task': ['Input[0]', 'Output[0]'],
        'order': ['AnimalReceiptCheck', 'OrderLocation[0]'],
        'sample': ['Material.MaterialSourceMaterial[0]'],
        'mating': ['MatingPlugDate[0]'],
        'permit': [
            'PermitInstitution[0]',
            'PermitSpecies[0]'
        ]
    };

    readonly ALL_REQUIRED_FIELDS_MUST_BE_FILLED_MESSAGE: string = "Ensure that all required fields are filled.";
    constructor(
        private dataManager: DataManagerService,
        private translationService: TranslationService,
        private taskValidationService: TaskValidationService,
        private featureFlagService: FeatureFlagService
    ) {
        super();
        this.initIsGLP();
    }

    initIsGLP() {
        this.isGLP = this.featureFlagService.isFlagOn('IsGLP');
    }

    getSettings(): Promise<any> {
        const query = EntityQuery.from(this.ENTITY_TYPE);

        return this.dataManager.returnQueryResults(query);
    }

    getSettingByName(settingName: SettingName | string): Promise<any> {
        let query = EntityQuery.from(this.ENTITY_TYPE);

        const settingNamePredicate = Predicate.create('SettingName', 'eq', settingName);
        query = query.where(settingNamePredicate);

        return this.dataManager.returnSingleQueryResult(query);
    }

    getDefaultHealthTechSetting(): Promise<any> {
        return this.getSettingByName(SettingName.DefaultHealthTechnician);
    }

    getInactivityTimeoutSetting(): Promise<any> {
        return this.getSettingByName(SettingName.InactivityTimeout);
    }

    // Since active fields are only used in the UI, dealing with display names is easier than field names which are sometimes NULL
    getActiveFields(facetSettings: IFacetSetting[]): string[] {
        return facetSettings.filter((setting: IFacetSetting) => setting.IsActive).map((setting: IFacetSetting) => {
            return this.removeFacetSettingDisplayNameTranslationMarkers(setting.DisplayName);
        });
    }

    // For use in Bulk Edit component to filter out inactive labels
    getInactiveFields(facetSettings: IFacetSetting[]): string[] {
        return facetSettings.filter((setting: IFacetSetting) => !setting.IsActive).map((setting: IFacetSetting) => {
            return this.removeFacetSettingDisplayNameTranslationMarkers(setting.DisplayName);
        });
    }

    // Actual field names are needed for required fields to check if entity contains prop with exact path
    getRequiredFields(facetSettings: IFacetSetting[]): string[] {
        return facetSettings.filter((setting: IFacetSetting) => setting.IsRequired).map((setting: IFacetSetting) => setting.Field);
    }

    // get the actual field names of the active fields. 
    getActiveFieldValues(facetSettings: IFacetSetting[]): string[] {
        return facetSettings.filter((setting: IFacetSetting) => setting.IsActive).map((setting: IFacetSetting) => setting.Field);
    }

    async getFacetSettingsByType(facetType: FacetType, isCRO?: boolean, isGLP?: boolean, isCRL?: boolean): Promise<IFacetSetting[]> {
        const predicates: Predicate[] = [];

        predicates.push(Predicate.create('tolower(FacetType)', 'eq', facetType.toLowerCase()));

        if (!isGLP) {
            predicates.push(Predicate.create('IsGLPOnly', '==', false));
        } else {
            predicates.push(Predicate.create('tolower(Field)', 'eq', "c_materialType_key").not());
        }

        if (!isCRL) {
            predicates.push(Predicate.create('IsCRLOnly', '==', false));
        }

        if (isCRO) {
            predicates.push(Predicate.create('IsNonCROOnly', FilterQueryOp.Equals, false));
        } else {
            predicates.push(Predicate.create('IsCROOnly', '==', false));
        }

        predicates.push(Predicate.create('tolower(Field)', 'eq', "studydirector").not());

        const query = EntityQuery.from('FacetSettings')
            .where(Predicate.and(predicates))
            .orderBy('SortOrder');

        let settings = await this.dataManager.returnQueryResults(query) as IFacetSetting[];

        if (facetType === 'job' && !isCRO && !this.featureFlagService.isFlagOn('DosingTable')) {
            settings = settings.filter(setting => setting.DisplayName !== 'Dosing Table');
        }
        return settings;
    }

    async getFacetSettingsByTypeAndField(facetType: FacetType, field: string): Promise<IFacetSetting[]> {
        const predicates: Predicate[] = [];

        predicates.push(Predicate.create('tolower(FacetType)', 'eq', facetType.toLowerCase()));
        predicates.push(Predicate.create('tolower(Field)', 'eq', field.toLowerCase()));

        const query = EntityQuery.from('FacetSettings')
            .where(Predicate.and(predicates))
            .orderBy('SortOrder');

        return await this.dataManager.returnQueryResults(query) as IFacetSetting[];
    }

    async getFacetSettingDisplayName(facetType: FacetType, field: string): Promise<string> {
        const predicates: Predicate[] = [];

        predicates.push(Predicate.create('tolower(FacetType)', 'eq', facetType.toLowerCase()));
        predicates.push(Predicate.create('tolower(Field)', 'eq', field.toLowerCase()));

        const query = EntityQuery.from('FacetSettings')
            .where(Predicate.and(predicates));
        const results = await this.dataManager.returnQueryResults(query) as IFacetSetting[];

        if (notEmpty(results)) {
            const setting = results[0];
            return this.translateFacetSettingDisplayName(setting.DisplayName);
        }

        return '[Unknown field]';
    }

    getAllFacetSettings(isCRO: boolean, isGLP: boolean, isCRL: boolean): Promise<IFacetSetting[][]> {
        return Promise.all([
            this.getFacetSettingsByType('animal', undefined, isGLP),
            this.getFacetSettingsByType('order', undefined, isGLP),
            this.getFacetSettingsByType('order-animal', undefined, undefined),
            this.getFacetSettingsByType('task', undefined, undefined),
            this.getFacetSettingsByType('job', isCRO, isGLP, isCRL),
            this.getFacetSettingsByType('resource', undefined, undefined),
            this.getFacetSettingsByType('clinical', undefined, isGLP),
            this.getFacetSettingsByType('housing', undefined, isGLP),
            this.getFacetSettingsByType('protocol', isCRO, undefined),
            this.getFacetSettingsByType('study', undefined, undefined),
            this.getFacetSettingsByType('sample', undefined, undefined),
            this.getFacetSettingsByType('mating', undefined, undefined),
            this.getFacetSettingsByType('permit', undefined, undefined)
        ]);
    }

    async validateRequiredFields(requiredFields: string[], entity: any, facetType: FacetType): Promise<string> {
        let errMsg: string;

        if (!entity) {
            return 'Unable to validate entity.';
        }

        for (const field of requiredFields) {
            if (!field) {
                continue;
            }

            if (this.hasSpecialValidation(field, facetType)) {
                let error: string;

                switch (facetType) {
                    case 'job':
                        error = await this.handleSpecialValidationInJob(entity, field, facetType);
                        break;
                    case 'task':
                        error = await this.handleSpecialValidationInTask(entity, field);
                        break;
                    case 'order':
                        error = await this.handleSpecialValidationInOrder(entity, field);
                        break;
                    case 'sample':
                        error = await this.handleSpecialValidationInSample(entity, field);
                        break;
                    case 'mating':
                        error = await this.handleSpecialValidationInMating(entity, field);
                        break;
                    case 'permit':
                        error = await this.handleSpecialValidationInPermit(entity, field);
                }

                if (error) {
                    errMsg = error;
                    break;
                }
            } else {
                if (!this.isPropValid(entity, field)) {
                    let displayName = await this.getFacetSettingDisplayName(facetType, field);
                    if (this.isGLP && displayName.toLowerCase() === "client manager") {
                        displayName = "Alternate Contact";
                    }
                    errMsg = `The ${displayName} field has been required in Facet Settings.`;

                    // Field is mult-entry if path ends with index into first element of array
                    if (field.endsWith('[0]')) {
                        errMsg += ' At least one entry is required.';
                    }

                    break;

                }

                if (facetType === 'housing' && field === 'MaterialPoolMaterial[0]') {
                    if (!entity.MaterialPoolMaterial.find((material: any) => material.DateOut === null)) {
                        errMsg = `Save failed: The Animals field has been required in Facet Settings. At least one entry is required.`;
                        break;
                    }
                }

                if (facetType === 'mating' && field === 'MaterialPool.MaterialPoolMaterial[0]') {
                    if (!entity.MaterialPool.MaterialPoolMaterial.find((material: any) => material.DateOut === null)) {
                        errMsg = `Save failed: The Animals field has been required in Facet Settings. At least one entry is required.`;
                        break;
                    }
                }
            }
        }

        return errMsg;
    }

    translateFacetSettingDisplayName(displayName: string): string {
        const indexOfOpenBracket = displayName.indexOf('[');
        const indexOfCloseBracket = displayName.lastIndexOf(']');

        const wordToTranslate = displayName.substring(indexOfOpenBracket + 1, indexOfCloseBracket);
        const translatedWord = this.translationService.translate(wordToTranslate);

        const prefix = displayName.substring(0, indexOfOpenBracket);
        const suffix = displayName.substring(indexOfCloseBracket + 1);

        return prefix + translatedWord + suffix;
    }

    getTaxonCharacteristicsShownInListView(): Promise<TaxonCharacteristic[]> {
        const predicates = [];
        predicates.push(Predicate.create("ListViewOrder", ">", 0));
        predicates.push(Predicate.create("IsActive", "eq", true));

        const query = EntityQuery.from("TaxonCharacteristics")
            .where(Predicate.and(predicates))
            .orderBy("ListViewOrder");
        return this.dataManager.returnQueryResults(query);
    }

    /**
     * Validates a list of objects to ensure that they have the required fields.
     * @param elements
     * @param requiredFields
     * @param facetType 
     */
    async bulkValidate(elements: any[], requiredFields: string[], facetType: FacetType) {
        let errMsg;

        // Validate fields required by facet settings for each mating
        for (const el of elements) {
            if (!errMsg) {
                errMsg = await this.validateRequiredFields(requiredFields, el, facetType);
            }

            if (errMsg) {
                break;
            }
        }

        return errMsg;
    }

    private removeFacetSettingDisplayNameTranslationMarkers(displayName: string): string {
        displayName = displayName.replace('[', '');
        displayName = displayName.replace(']', '');

        return displayName;
    }

    private isPropValid(entity: any, field: string): boolean {
        const prop = getSafeProp(entity, field);

        return notEmpty(prop) || prop === true || prop === false;
    }

    private hasSpecialValidation(field: any, facetType: FacetType): boolean {
        return this.specialValidationFields[facetType]?.some((f: string) => f === field);
    }

    private async handleSpecialValidationInJob(job: any, field: string, facetType: FacetType): Promise<string> {
        switch (field) {
            case 'JobInstitution[0]': {
                const translatedInstitution = this.translationService.translate('Institution');
                const jobInstitutionValid = job.JobInstitution?.length > 0 &&
                    job.JobInstitution.every((item: any) => item.C_Institution_key);

                return jobInstitutionValid ? '' : `At least one ${translatedInstitution} entry is required. ${this.ALL_REQUIRED_FIELDS_MUST_BE_FILLED_MESSAGE}` ;

            }
            case 'JobLocation[0]': {
                const jobLocationValid = job.JobLocation?.length > 0 &&
                    job.JobLocation.every((item: any) => item.LocationPosition);

                return jobLocationValid ? '' : `At least one Location entry is required. ${this.ALL_REQUIRED_FIELDS_MUST_BE_FILLED_MESSAGE}`;
            }
            case 'JobGroup.JobGroupTreatment[0]': {
                const displayName = await this.getFacetSettingDisplayName(facetType, field);
                const jobGroupValid = job.JobGroup?.length > 0;

                return jobGroupValid ? '' : `At least one ${displayName} entry is required. ${this.ALL_REQUIRED_FIELDS_MUST_BE_FILLED_MESSAGE}`;
            }
            case 'JobLine[0]': {
                const translatedLine = this.translationService.translate('Line');
                const jobLineValid = job.JobLine?.length > 0 &&
                    job.JobLine.every((item: any) => item.C_Line_key);

                return jobLineValid ? '' : `At least one ${translatedLine} entry is required. ${this.ALL_REQUIRED_FIELDS_MUST_BE_FILLED_MESSAGE}`;
            }
            case 'JobTestArticle[0]': {
                // First we need to filter out system generated articles
                const testArticles = (job.JobTestArticle ?? []).filter((item: any) => !item.cv_TestArticle?.IsSystemGenerated);
                const jobTestArticleValid = testArticles.length > 0 &&
                    testArticles.every((item: any) => item.C_TestArticle_key);

                return jobTestArticleValid ? '' : `At least one Test Article entry is required. ${this.ALL_REQUIRED_FIELDS_MUST_BE_FILLED_MESSAGE}`;
            }
            default:
                return '';
        }
    }

    private async handleSpecialValidationInTask(task: any, field: string): Promise<string> {
        switch (field) {
            case 'Input[0]': {
                const taskInputValid = task.Input?.length > 0 && task.Input.every((taskInput: any) => this.taskValidationService.isValidTaskInput(taskInput));

                return taskInputValid ? '' : `At least one Input entry is required. ${this.ALL_REQUIRED_FIELDS_MUST_BE_FILLED_MESSAGE}`;

            }
            case 'Output[0]': {
                const taskOutputValid = task.Output?.length > 0 && task.Output.every((taskOutput: any) => this.taskValidationService.isValidTaskOutput(taskOutput));

                return taskOutputValid ? '' : `At least one Output entry is required. ${this.ALL_REQUIRED_FIELDS_MUST_BE_FILLED_MESSAGE}`;
            }
            default:
                return '';
        }
    }

    private async handleSpecialValidationInOrder(order: any, field: string): Promise<string> {
        switch (field) {
            case 'AnimalReceiptCheck': {
                if (order.cv_MaterialType?.MaterialType === 'Animal') {
                    const isAnimalHousingValid = order.AnimalHousing === 'Single' || order.AnimalHousing === 'Social';
                    const isWaterAvailableValid = order.IsWaterAvailable === true || order.IsWaterAvailable === false;
                    const isFoodAvailableValid = order.IsFoodAvailable === true || order.IsFoodAvailable === false;
                    const isBeddingAvailableValid = order.IsBeddingAvailable === true || order.IsBeddingAvailable === false;
                    const isAllShipmentContainersEmptyValid = order.IsAllShipmentContainersEmpty === true ||
                        order.IsAllShipmentContainersEmpty === false;

                    const isValidOrder = isAnimalHousingValid && isWaterAvailableValid && isFoodAvailableValid &&
                        isBeddingAvailableValid && isAllShipmentContainersEmptyValid;

                    if (!isValidOrder) {
                        return 'Animal Receipt Check has been required in Facet Settings. ' +
                            'One box or the other must be checked in each of the 5 categories.';
                    }
                }

                return '';
            }
            case 'OrderLocation[0]': {
                const areLocationsvalid = order.OrderLocation?.length > 0 && order.OrderLocation.every((location: any) => location.C_LocationPosition_key);
                return areLocationsvalid ? '' : `At least one Location entry is required. ${this.ALL_REQUIRED_FIELDS_MUST_BE_FILLED_MESSAGE}`;
            }
            default:
                return '';
        }
    }

    private async handleSpecialValidationInSample(sample: any, field: string): Promise<string> {
        switch (field) {
            case 'Material.MaterialSourceMaterial[0]': {
                const sources = sample.Material.MaterialSourceMaterial;
                if (sources && sources.length === 0) {
                    return `At least one Source is required. ${this.ALL_REQUIRED_FIELDS_MUST_BE_FILLED_MESSAGE}`;
                } else {
                    return '';
                }
            }
            default:
                return '';
        }
    }

    private async handleSpecialValidationInMating(mating: any, field: string): Promise<string> {
        switch (field) {
            case 'MatingPlugDate[0]': {
                // in order for a plug date to be valid it must have a Dam and a plug date. 
                const validPlugDates = mating.MatingPlugDate.filter((matingPlugDate: any) => {
                    return matingPlugDate.Dam && matingPlugDate.DatePlug;
                }).length;
                const totalPlugDates: number = mating.MatingPlugDate?.length;

                if (validPlugDates === 0 || validPlugDates !== totalPlugDates) {
                    return `Plug date has been required in Facet Settings. ${this.ALL_REQUIRED_FIELDS_MUST_BE_FILLED_MESSAGE}`;
                } else {
                    return '';
                }
            }
        }
    }

    private async handleSpecialValidationInPermit(permit: Permit, field: string): Promise<string> {
        switch (field) {
            case "PermitInstitution[0]":
                if (permit.PermitInstitution?.length == 0) {
                    return `Permit Institution has been required in Facet Settings. ${this.ALL_REQUIRED_FIELDS_MUST_BE_FILLED_MESSAGE}`;
                }
            case "PermitSpecies[0]":
                if (permit.PermitSpecies?.length == 0) {
                    return `Permit Species has been required in Facet Settings. ${this.ALL_REQUIRED_FIELDS_MUST_BE_FILLED_MESSAGE}`;
                }
            default:
                return ''
        }
    }
}
